/**
 * Banner provides a container with a title and image.
 */
import React from 'react';
import Safe from './Safe';

export default function Banner(props) {
  return (
    <div id="contact-us" className="flu-vaccine-bg">
      <div className="container-fluid banner-v-pad">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 banner-pad">
            <h1 className="banner-title">{props.src.title}</h1>
            <Safe type="h3" className="banner-text" content={props.src.subtitle} />
          </div>
        </div>
      </div>
    </div>
  );
};
