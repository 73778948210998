
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Safe from './Safe';
import StudyTile from './StudyTile';

export default function StudyDashboard(props) {
  const localized = props.localizerfn;
  const activeStudies = props.studies.filter(study => !Boolean(study.hideStudy));
  const groupedStudies = renderStudies(activeStudies.sort(compare), 2);

  const studyImages = {
    mrnaphase3: <StaticImage src="../images/mRna-p3.jpg" alt="mRNA phase 3 study" />
  };

  return (
    <div className="container-fluid">
      <div>
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
            <article className="node node-page view-mode-full clearfix node-1" about="/home" typeof="foaf:Document">
              <header><span property="dc:title" content="Home" className="rdf-meta element-hidden"></span></header>
              <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                <div className="field-items">
                  <div className="field-item home-section-text" property="content:encoded">
                    <h2 id="why-participate">{props.studyIntro.header}</h2>
                    {renderParagraphs(props.studyIntro.text)}
                    <div id="the-studies">
                      {groupedStudies.map((studyrow, index) => {
                        return (
                          <div key={`group_${index}`} className="row study-info-row">
                            {studyrow.map((item, index) => (
                              <StudyTile key={item.slug}
                                         config={item}
                                         linklabel={item.studylink || props.studyIntro.studylink}
                                         image={studyImages[item.slug]}
                                         localizerfn={localized} />
                            ))}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

// Compare the studies by "seq" field
function compare(a, b) {
  if (a.seq<b.seq) return -1;
  else if (a.seq>b.seq) return 1;
  else return 0;
}

function renderStudies(studies, columns) {
  return studies.reduce((group, study, index) => {
    const isNewRow = ((index%columns)===0);
    const currRow = (isNewRow ? [] : group[group.length-1])
    currRow.push(study);
    if (isNewRow) group.push(currRow);
    return group;
    }, []);
}

function renderParagraphs(items) { return items.map((item, index) => <Safe type="p" key={`p_${index}`} content={item} />); }
