/* Home page */

import React from 'react';
import { withPrefix, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/HomeLayout';
import HomeSection from '../components/HomeSection';

export default function Home(props) {
  const ctx = props.pageContext;
  const studies = props.data.localStudies.edges.map(study => study.node);
  const sitedata = props.data.homePage;

  return (
    <Layout config={sitedata} studies={studies} ctx={ctx} currentpath={props.path}>
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>{sitedata.sitename}</title>
        <link rel="canonical" href={sitedata.siteurl} />
        <meta name="description" content={sitedata.metaDescription}></meta>
        <script src={withPrefix('optanonwrapper.js')} />
      </Helmet>
      <HomeSection config={sitedata.homeSection} />
    </Layout>
  );
};

export const query = graphql`
  query indexQ($locale: String) {
    localStudies: allStudyYaml(filter: {locale: {eq: $locale}}) {
      edges {
        node {
          locale
          name
          seq
          siteurl
          slug
          linkto
          title
          bannertitle
          subtitle
          tiletitle
          description
          hideStudy
          studylink
        }
      }
    }
    homePage: siteYaml(locale: {eq: $locale}) {
      locale
      sitename
      siteurl
      title
      subtitle
      homelink
      metaDescription
      studyList {
        linkto
        header
        studylink
        text
      }
      links {
        id
        title
        linkto
      }
      homeSection {
        title
        text
      }
      footer {
        links {
          label
          to
        }
        socialLinkLabel
        text
      }
    }
  }`;
