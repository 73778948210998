
import React from 'react';
import Safe from './Safe';

export default function HomeSection(props) {
  const config = props.config;
  return (
    <div className="region region-content-blocks">
      <div className="block block-bean first odd" id="block-bean-about-the-study">
        <div className="container-fluid bg-pary text-white">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
              <div className="bean-content-section clearfix">
                <Safe className="fw-200" type="h2" content={config.title} />
                <div className="content">
                  <div className="field field-name-field-content-section-body field-type-text-with-summary field-label-hidden">
                    <div className="field-items">
                      <div className="field-item even home-section-text">
                        {renderParagraphs(config.text)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block block-bean last even bg-home-section" id="block-bean-qa">
        <div className="content-section-block container-fluid">
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2">
              <div className="entity entity-bean bean-content-section clearfix">
                <div className="content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(items) {
  return items.map((item, index) => <Safe type="p" key={`p_${index}`} content={item} />);
}
