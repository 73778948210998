/**
 * Render a tile for a study including a title, image, and description.
 * Supported props: config linklabel image localizerfn
 */
import React from 'react';
import { Link } from 'gatsby';
import Safe from './Safe';

export default function StudyTile(props) {
  const cfg = props.config;
  const studylink = props.localizerfn(cfg.linkto);
  const studyImage = (cfg.siteurl ? <Link to={studylink}>{props.image}</Link> : <span>{props.image}</span>);
  const studyTitle = (cfg.siteurl ?
    <h3><Safe type="link" to={studylink} content={cfg.tiletitle} /></h3>
    :
    <h3><Safe type="span" className="tile-title" content={cfg.tiletitle} /></h3>
  );

  return (
    <div className="col-sm-6">
      {studyImage}
      <div className="study-info">
        {studyTitle}
        <Safe type="p" content={cfg.description} />
        {cfg.siteurl &&
          <div><Safe type="link" to={studylink} className="btn btn-study-info" content={props.linklabel} /></div>
        }
        {!cfg.siteurl &&
          <div className="btn-placeholder">&nbsp;</div>
        }
      </div>
    </div>
  );
};
