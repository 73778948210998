/**
 * HomeLayout defines the main page structure for the home page.
 * Supported props: config, ctx, currentpath
 */

import React from 'react';
import Banner from './Banner';
import Nav from './Nav';
import Footer from './Footer';
import StudyDashboard from '../components/StudyDashboard';
import { makeTopLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function HomeLayout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = makeTopLinks(cfg.links);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };

  return (
    <div id="page">
      <Nav toplinks={toplinks}
           sitename={cfg.sitename}
           ctx={props.ctx}
           currentpath={props.currentpath}
           localizerfn={localized} />
      <Banner src={cfg} />
      <div id="main">
        <StudyDashboard studies={props.studies} studyIntro={cfg.studyList} localizerfn={localized} />
        {props.children}
      </div>
      <Footer src={footdata} localizerfn={localized} />
    </div>
  );
};
